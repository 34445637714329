import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/Layout";
import StandardBanner from "../components/StandardHero/StandardHero";
import SubNav from "./SubNav/SubNav";

const InvestorsLayout = ({ children, currentPath, seo }) => {
  const { banner } = useStaticQuery(
    graphql`
      {
        banner: strapiSolutionsInvestors {
          hero {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            title
            showBreadcrumb
          }
        }
      }
    `
  );

  const bannerImage =
    banner.hero.image.localFile.childImageSharp.gatsbyImageData;
  const bannerTitle = banner.hero.title;
  const showBreadcrumb = banner.hero.showBreadcrumb;

  const breadcrumbs = [{ name: "WHO WE HELP" }, { name: "INVESTORS" }];

  const navArray = [
    { name: "Pension schemes", path: "/solutions/investors/pension-schemes" },
    {
      name: "Insurance companies",
      path: "/solutions/investors/insurance-company",
    },
    {
      name: "Multinational pension schemes",
      path: "/solutions/investors/multinational-pensions",
    },
  ];
  return (
    <Layout seo={seo}>
      <StandardBanner
        image={bannerImage}
        title={bannerTitle}
        showBreadcrumb={showBreadcrumb}
        breadcrumbs={breadcrumbs}
      />
      <SubNav navArray={navArray} currentPath={currentPath} />
      {children}
    </Layout>
  );
};

export default InvestorsLayout;
